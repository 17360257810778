<template>
  <v-container>
    <v-card outlined class="pa-1 mb-1 rounded-xl mx-auto" color="transparent">
      <v-layout wrap>
        <div name="likeLanguage" id="" class="pl">
          <span class="ml-1"> 전시회 </span>
        </div>
        <v-btn class="elevation-5 ma-1 rounded-pill normal-btn" style="height: 40px; width: 110px"
          id="refreshList" 
          :loading="loading" 
          ref="refreshList" 
          @click="refreshList()">
          <img src="@/assets/icon_refresh.svg" alt="새로고침" />
          조회
        </v-btn>
      </v-layout>
    </v-card>

    <div class="model_content">
      <div class="fog_title">
        <div class="model_title">안개분무 01</div>

        <div>
          <v-btn block
            class="set_btn rounded-circle"
            style="width:35px !important; height:35px !important;"
            v-if="monitoring"
            @click="setting()">
            <img :src="set_icon" class="setting_icon" />
          </v-btn>
          <v-btn block
            class="set_btn"
            style="width:50px !important; border-radius: 15px; font-size:15px;"
            v-else
            @click="save()">
            저장
          </v-btn>
        </div>
      </div>

      <div v-if="monitoring">
        <div class="fogline_content">

          <div class="fogline_info" style="width:95px;">
            <div class="fogline_title">잔여 시간</div>
            <div class="active_info" style="background-color:#EDEDED; border:1px solid transparent !important;">
              <div class="fogline_time">{{ fog.timer_count }}</div>
              <div style="font-size:15px;">초</div>
            </div>
          </div>

          <div class="fogline_info">
            <div class="fogline_title">분사1</div>
            <div class="active_info" :class="fog.cur_op_state == '1' ? 'active':'noactive' ">
              <div class="fogline_time">{{ fog.flash_fog1_dur }}</div>
              <div style="font-size:15px;">십초</div>
            </div>
            <div class="fogline_status_on" v-if="fog.cur_op_state == '1' "></div>
            <div class="fogline_status_off" v-else></div>
          </div>

          <div class="fogline_info">
            <div class="fogline_title">분사2</div>
            <div class="active_info" :class="fog.cur_op_state == '2' ? 'active':'noactive' ">
              <div class="fogline_time">{{ fog.flash_fog2_dur }}</div>
              <div style="font-size:15px;">분</div>
            </div>
            <div class="fogline_status_on" v-if="fog.cur_op_state == '2' "></div>
            <div class="fogline_status_off" v-else></div>
          </div>

          <div class="fogline_info">
            <div class="fogline_title">에어</div>
            <div class="active_info"  :class="fog.cur_op_state == '3' ? 'active':'noactive' ">
              <div class="fogline_time">{{ fog.flash_air_dur }}</div>
              <div style="font-size:15px;">초</div>
            </div>
            <div class="fogline_status_on" v-if="fog.cur_op_state == '3' "></div>
            <div class="fogline_status_off" v-else></div>
          </div>

          <div class="fogline_info">
            <div class="fogline_title">쉬는시간</div>
            <div class="active_info">
              <div class="fogline_time">{{ fog.flash_rest }}</div>
              <div style="font-size:15px;">분</div>
            </div>
            <div class="fogline_status_on" v-if="fog.cur_op_state == '0' "></div>
            <div class="fogline_status_off" v-else></div>
          </div>

          <div class="fogline_info">
            <div class="fogline_title">온도설정</div>
            <div class="active_info">
              <div class="fogline_time">{{ fog.flash_temp }}</div>
              <div style="font-size:15px;">℃</div>
            </div>
            <!-- <div class="fogline_status_off"></div> -->
          </div>


          <div>
            <div class="status_info">
              <div style="width:120px; text-align:center;">
                <div class="fogline_title">스위치</div>
                <div style="font-size:11px;">(자동/정지/수동)</div>
              </div>
              <div class="status_mode">
                <div v-if="fog.cur_mode == '1' ">자동</div>
                <div v-else-if="fog.cur_mode == '2' ">정지</div>
                <div v-else>수동</div>
              </div>
            </div>
            
            <!-- <div class="status_info">
              <div style="width:120px; text-align:center;">
                <div class="fogline_title">현재모드</div>
                <div style="font-size:11px;">(로컬/원격)</div>
              </div>
              <div class="status_mode">
                <div v-if="fog.cur_op_state == '1' "> 로컬 </div>
                <div v-else-if="fog.cur_op_state == '2' "> 원격 </div>
                <div v-else> - </div>
              </div>
            </div> -->

            <div class="status_info">
                <div style="width:120px; text-align:center;">
                  <div class="fogline_title">온도 센서값</div>
                </div>
                <div class="status_mode">{{fog.cur_temp}} ℃</div>
              </div>
            </div>

          </div>
        </div>

        <div v-else>
          <div class="fogline_content">
            <div class="fogline_info">
              <div class="fogline_title">분사1</div>
              <input 
                type="number"
                ref="fog1_dur"
                class="control_value"
                v-model.number="fog1_dur"
              >
              <div class="control_unit">십초</div>
            </div>

            <div class="fogline_info">
              <div class="fogline_title">분사2</div>
              <input 
                type="number"
                ref="fog2_dur"
                class="control_value"
                v-model.number="fog2_dur"
              >
              <div class="control_unit">분</div>
            </div>

            <div class="fogline_info">
              <div class="fogline_title">에어</div>
              <input 
                type="number"
                ref="air_dur"
                class="control_value"
                v-model.number="air_dur"
              >
              <div class="control_unit">초</div>
            </div>

            <div class="fogline_info">
              <div class="fogline_title">쉬는시간</div>
              <input 
                type="number"
                ref="rest"
                class="control_value"
                v-model.number="rest"
                >
                <div class="control_unit">분</div>
            </div>

            <div>
              <div class="status_info">
                <div style="width:120px; text-align:center;">
                  <div class="fogline_title">스위치</div>
                  <div style="font-size:11px;">(자동/정지/수동)</div>
                </div>
                <div class="status_mode" style="color:gray;">
                  <div v-if="fog.cur_mode == '1' ">자동</div>
                  <div v-else-if="fog.cur_mode == '2' ">정지</div>
                  <div v-else>수동</div>
                </div>
              </div>

              <!-- <div class="status_info">
                <div style="width:120px; text-align:center;">
                  <div class="fogline_title">현재모드</div>
                  <div style="font-size:11px;">(로컬/원격)</div>
                </div>
                <div class="status_mode">
                  <div v-if="fog.cur_op_state == '1' "> 로컬 </div>
                  <div v-else-if="fog.cur_op_state == '2' "> 원격 </div>
                  <div v-else> - </div>
                </div>
              </div> -->

              <div class="status_info">
                <div style="width:120px; text-align:center;">
                  <div class="fogline_title">온도 센서값</div>
                </div>
                <div class="status_mode" style="color:gray;">{{fog.cur_temp}} ℃</div>
              </div>
            </div>

          </div>
        </div>
      </div>
  </v-container>
</template>

<script>
import Common from "@/utils/custom/common.js";
import Apis from '@/api/apis';
// import data from "@/assets/json/jy.json"

export default {
  name: "JYDeodorizer2",
  data(){
    return{
      set_icon: require("@/assets/monitoring/setting.svg"),

      fog:{},

      monitoring: true,

      timer:null,

      fog1_dur:'',
      fog2_dur:'',
      air_dur:'',
      rest:'',
      temp:'',


      loading: false,

    }
  },
  created(){
    this.refreshList();
  },
  mounted(){
    this.startMonitoring();
  },
  beforeDestroy() {
    this.stopMonitoring();
  },

  methods:{
    startMonitoring() {
      this.timer = setTimeout(() => {
        this.refreshList();
        this.startMonitoring(); 
      }, 5000);
    },
    stopMonitoring() {
      clearTimeout(this.timer);
    },
    setting(){
      this.monitoring = !this.monitoring;
      if (this.monitoring) {
        this.startMonitoring();
      } else {
        this.stopMonitoring();
      }
    },
    checkData(){
      if(!Common.isNumeric(this.fog1_dur) || this.fog1_dur < 0 || this.fog1_dur > 100 ){
        this.$refs.fog1_dur.focus();
          return "정확한 값을 입력하세요(0~990)";
      }

      if(!Common.isNumeric(this.fog2_dur) || this.fog2_dur < 0 || this.fog2_dur > 10 ){
        this.$refs.fog2_dur.focus();
          return "정확한 값을 입력하세요(0~10)";
      }

      if(!Common.isNumeric(this.air_dur) || this.air_dur < 0 || this.air_dur > 100 ){
        this.$refs.air_dur.focus();
          return "정확한 값을 입력하세요(0~99)";
      }

      if(!Common.isNumeric(this.rest) || this.rest < 0 || this.rest > 100 ){
        this.$refs.rest.focus();
          return "정확한 값을 입력하세요(0~99)";
      }

    },
    save(){  
      let msgCheckdata = this.checkData(); 
      if (msgCheckdata){
        this.$store.commit("resMessage",msgCheckdata);
        return;
      }
      if (!confirm("안개분무기 설정을 변경하시겠습니까?")) { return; }
      else{
        Apis.set({
          "mac" : "4827e213b68c",
          "sid" : 1,
          "setting" : {
              "fog1_dur" : this.fog1_dur,
              "fog2_dur" : this.fog2_dur,
              "air_dur" : this.air_dur,
              "rest" : this.rest
          }
        },(res) => {
          if (res.result) {
            this.$store.commit("resMessage",res.result);
            this.loadingSave = false;

            this.refreshList();
            
            this.monitoring = true;
            this.startMonitoring(); 
          } else {
            this.loadingSave = false;
            console.log("저장 오류",res.result)
          }
          this.refreshList();
        }).catch((err)=>{
          console.log("set API 호출 오류",err)
        })
      }
    },

    refreshList(){
      this.loading = true;

      Apis.get({
        "mac" : "4827e213b68c",
        "sid" : 1
        },(res) => {
          this.$store.commit("resMessage","정상처리되었습니다.");

          this.fog = res.setting;

          this.loading = false;
        }).catch((err)=>{
          console.log("get API 호출 오류",err)
        })
    },
  },
}
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.pl{
    width: 150px;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 20px;
    padding: 12px 13px;
    font-family: 'Roboto';
    /* font-style: normal; */
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}
.pl:focus{
    border: 1px solid #9B51E0;
    box-sizing: border-box;
    border-radius: 10px;
    outline: 3px solid #F8E4FF;
    border-radius: 10px;
}


@import url('https://fonts.cdnfonts.com/css/ds-digital');
@font-face {
  font-family: 'GmarketSansMedium';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  transform : rotate(0.04deg);
}

*{
  letter-spacing: -1.25px;
}
.normal-btn{
  align-content: center !important;
  color:white !important;
  font-weight: bold !important;
  background-color: #3a4f3f !important;
}
.set_btn{
  align-content: center !important;
  color:white !important;
  background-color: #3a4f3f !important;
}
.setting_icon{
  width:18px;
}
.model_content{
  padding:15px;
  margin:10px;
  /* border:1px solid #E2E2E2; */
  background: #f9f9f9;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
}
.model_title, .fogline_title, .fogline_time{
  font-weight:600;
  font-size:18px;
}
.fogline_time{
  font-size:40px;
  font-weight:bold;
  font-family: 'DS-Digital';
}
.fogline_content , .fog_title ,.status_info{
  display: flex;
  text-align:center;
  justify-content: center;
  align-items: center;
  align-content: center; 
}
.fog_title{
  justify-content: space-between;
}
.fogline_info{
  width:10%;
  height:170px;
  margin:5px 10px 5px 10px;
}
.active_info{
  padding:10px;
  border:1px solid gray;
  border-radius: 20px;
  background:white;
}
.noactive{
  background:white;
}
.active{
  border:1px solid transparent !important;
  background-color: #FFF8DC;
}
.status_info{
  margin-top:5px;
}
.status_mode{
  text-align:center;
  width:100px;
  background: #e3e3e3;
  padding:5px;
  border-radius: 10px;
}
.fogline_status_on{
  background-color:#88191a;
  width:30px;
  height:30px;
  border-radius: 50%;
  animation: blink-effect 1s step-end infinite;
  text-align: center;
  margin: 0 auto;
  margin-top:10px;
}
.fogline_status_off{
  background-color:#e5e5e5;
  width:30px;
  height:30px;
  border-radius: 50%;
  text-align: center;
  margin: 0 auto;
  margin-top:10px;
}
@keyframes blink-effect {
  50% {
    opacity: 0;
  }
}

.control_value{
  width:100%;
  height:120px;
  text-align:center;
  background-color: #FFF8DC;
  border-width: 0 0 1px;
  font-size:40px;
  font-weight:bold;
  font-family: 'DS-Digital';
  border-radius: 20px;
  transition: box-shadow 0.3s ease-in-out;
}
.control_value:focus{
  outline: none;
  border-color: #D5DAB9;
  box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
}
.control_unit{
  margin-top:-40px;
  margin-left:5px;
}

</style>